import { Component, EventEmitter, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DataSource } from "@angular/cdk/collections";
import { AppConfigService } from "../../app-config.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { KeyValue } from "@angular/common";
import { FormControl, FormGroup } from "@angular/forms";

export interface Filter {
  name: string;
  from: string;
  to: string;
  active: boolean;
}

@Component({
  selector: "app-away-message-dialog",
  templateUrl: "./away-message-dialog.component.html",
  styleUrls: ["./away-message-dialog.component.scss"],
})
export class AwayMessageDialogComponent implements OnInit {
  filter: Filter[] = [
    { name: "Monday", from: "8:00", to: "17:00", active: false },
    { name: "Tuesday", from: "8:00", to: "17:00", active: false },
    { name: "Wednesday", from: "8:00", to: "17:00", active: false },
    { name: "Thursday", from: "8:00", to: "17:00", active: false },
    { name: "Friday", from: "8:00", to: "17:00", active: false },
    { name: "Saturday", from: null, to: null, active: false },
    { name: "Sunday", from: null, to: null, active: false },
  ];
  timesGroup;
  away_message_text = "";
  constructor(
    public config: AppConfigService,
    public dialogRef: MatDialogRef<AwayMessageDialogComponent>,
    public auth: AuthenticationService
  ) {}

  getDay(dayKey) {
    const days = {
      mon: "MONDAY",
      tue: "TUESDAY",
      wed: "WEDNESDAY",
      thu: "THURSDAY",
      fri: "FRIDAY",
      sat: "SATURDAY",
      sun: "SUNDAY",
    };
    if (days[dayKey]) {
      return days[dayKey];
    }
    return dayKey;
  }

  ngOnInit() {
    this.auth.awayMessage = false;
    this.auth.io.emit("get-away-info", true);
  }
  originalOrder = (
    a: KeyValue<number, any>,
    b: KeyValue<number, any>
  ): number => {
    return 0;
  };
  onConfirm(): void {
    // const data = {
    //   times: this.filter,
    //   message: this.away_message_text,
    // };
    this.auth.io.emit("set-away-info", this.auth.awayMessage);
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
