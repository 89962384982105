<mat-dialog-content [class]="config.app">
  <div *ngIf="!auth.awayMessage">
    <mat-spinner></mat-spinner>
  </div>
  <div *ngIf="auth.awayMessage">
    <!-- <h1 mat-dialog-title>{{ "AUTOMATIC_RESPONSE.TITLE" | translate }}</h1> -->
    <div>
      <h2 style="display: flex; flex-direction: row; gap: 12px">
        <mat-slide-toggle
          [(ngModel)]="auth.awayMessage.information_message_active"
        ></mat-slide-toggle>
        {{ "AUTOMATIC_RESPONSE.SCHEDULED_TITLE" | translate }}
      </h2>
      <div>
        <table>
          <!-- loop auth.env.languages -->
          <tr *ngFor="let lang of auth.env.languages">
            <td>
              <h3>{{ "LANGUAGE_" + lang.toUpperCase() | translate }}</h3>
              <quill-editor
                [disabled]="!auth.awayMessage.information_message_active"
                [(ngModel)]="auth.awayMessage.information_message[lang]"
              ></quill-editor>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div *ngIf="auth.awayMessage">
      <h2 style="display: flex; flex-direction: row; gap: 12px">
        <mat-slide-toggle
          [(ngModel)]="auth.awayMessage.away_message_active"
        ></mat-slide-toggle>
        {{ "AUTOMATIC_RESPONSE.DURING_WORKING_HOURS_TITLE" | translate }}
      </h2>
      <h3>{{ "AUTOMATIC_RESPONSE.DURING_WORKING_DAYS" | translate }}</h3>
      <table>
        <tr *ngFor="let lang of auth.env.languages">
          <td>
            <h3>{{ "LANGUAGE_" + lang.toUpperCase() | translate }}</h3>
            <quill-editor
              [disabled]="!auth.awayMessage.away_message_active"
              [(ngModel)]="auth.awayMessage.away_message_weekday[lang]"
            ></quill-editor>
          </td>
        </tr>
      </table>

      <h3>{{ "AUTOMATIC_RESPONSE.DURING_WEEKEND" | translate }}</h3>
      <table>
        <tr *ngFor="let lang of auth.env.languages">
          <td>
            <h3>{{ "LANGUAGE_" + lang.toUpperCase() | translate }}</h3>
            <quill-editor
              [disabled]="!auth.awayMessage.away_message_active"
              [(ngModel)]="auth.awayMessage.away_message_weekend[lang]"
            ></quill-editor>
          </td>
        </tr>
      </table>
    </div>
    <h2>{{ "AUTOMATIC_RESPONSE.DEFINE_WORKING_HOURS" | translate }}</h2>
    <div *ngIf="auth.awayMessage">
      <mat-card
        appearance="outlined"
        *ngFor="
          let variant of auth.awayMessage.times | keyvalue : originalOrder
        "
      >
        <mat-card-content>
          {{
            "APP.DAYS." +
              (getDay(variant.key) ?? (variant.value.name_en | uppercase))
              | translate
          }}
          <div
            class="time-items"
            style="margin-top: 12px"
            *ngIf="auth.awayMessage?.times?.[variant.key]"
          >
            <div *ngIf="auth.awayMessage.times[variant.key]">
              <mat-checkbox
                [(ngModel)]="auth.awayMessage.times[variant.key].active"
              ></mat-checkbox>
            </div>
            <div class="time-item">
              <mat-label>
                {{ "AUTOMATIC_RESPONSE.TIME_FROM" | translate }}:
                <ngx-mat-timepicker-field
                  [(ngModel)]="auth.awayMessage.times[variant.key].from"
                  [disabled]="!auth.awayMessage.times[variant.key].active"
                  [format]="24"
                  [defaultTime]="'8:00'"
                />
              </mat-label>
            </div>
            <div class="time-item">
              <mat-label>
                {{ "AUTOMATIC_RESPONSE.TIME_TO" | translate }}:
                <ngx-mat-timepicker-field
                  [(ngModel)]="auth.awayMessage.times[variant.key].to"
                  [disabled]="!auth.awayMessage.times[variant.key].active"
                  [format]="24"
                  [defaultTime]="'17:00'"
                />
              </mat-label>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onDismiss()">
    {{ "BUTTON_CANCEL" | translate }}
  </button>
  <button
    [disabled]="!auth.awayMessage"
    mat-raised-button
    color="primary"
    (click)="onConfirm()"
  >
    {{ "BUTTON_SAVE" | translate }}
  </button>
</mat-dialog-actions>
