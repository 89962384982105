import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { CustomMaterialModule } from "../custom-material/custom-material.module";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { ContentPlaceholderAnimationComponent } from "./content-placeholder-animation/content-placeholder-animation.component";
import { LayoutComponent } from "./layout/layout.component";
import { ListUserComponent } from "./list-user/list-user.component";
import { ChatMessageComponent } from "./chat-message/chat-message.component";
import { MatTooltipModule } from "@angular/material/tooltip";
import { AwayMessageDialogComponent } from "./away-message-dialog/away-message-dialog.component";
import { MatTableModule } from "@angular/material/table";
import { MatDialogModule } from "@angular/material/dialog";
import { QuillModule } from "ngx-quill";
import { ReflectionTagcloudComponent } from "./reflection-tagcloud/reflection-tagcloud.component";
import { StatisticsPosNegComponent } from "./statistics-pos-neg/statistics-pos-neg.component";
import { PlotlyViaWindowModule } from "angular-plotly.js";
import { NgxMasonryModule } from "ngx-masonry";
import { TranslateModule } from "@ngx-translate/core";
import { InfoMessageDialogComponent } from "./info-message-dialog/info-message-dialog.component";
import { EncryptionSetupDialogComponent } from "./encryption-setup-dialog/encryption-setup-dialog.component";
import { ChangePasswordDialogComponent } from "./change-password-dialog/change-password-dialog.component";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { NgxMatTimepickerModule } from "ngx-mat-timepicker";

@NgModule({
  imports: [
    RouterModule,
    CustomMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    NgxMatTimepickerModule.setLocale("nl-NL"),
    MatTableModule,
    MatDialogModule,
    TranslateModule,
    QuillModule,
    PlotlyViaWindowModule,
    NgxMasonryModule,
  ],
  declarations: [
    ConfirmDialogComponent,
    ContentPlaceholderAnimationComponent,
    LayoutComponent,
    ListUserComponent,
    ChatMessageComponent,
    AwayMessageDialogComponent,
    ChangePasswordDialogComponent,
    ReflectionTagcloudComponent,
    StatisticsPosNegComponent,
    InfoMessageDialogComponent,
    EncryptionSetupDialogComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    CustomMaterialModule,
    ConfirmDialogComponent,
    ContentPlaceholderAnimationComponent,
    ChatMessageComponent,
    ListUserComponent,
    AwayMessageDialogComponent,
    ReflectionTagcloudComponent,
    StatisticsPosNegComponent,
    InfoMessageDialogComponent,
    EncryptionSetupDialogComponent,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: "outline", subscriptSizing: "dynamic" },
    },
  ],
})
export class SharedModule {}
