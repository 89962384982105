<mat-list-item [routerLink]="link">
  <img
    matListItemAvatar
    [src]="
      data.image && status !== false ? data.image : 'assets/img/account.svg'
    "
    alt="..."
  />
  <div class="name-statuses">
    <div class="name-lastmessage">
      <div class="name">
        {{ name ? name : ("CHAT.NO_NAME" | translate) }}
      </div>
      <div
        class="last-message"
        *ngIf="chatData && chatData.lastMessageText"
        [class.typing]="chatId && auth.typing[chatId]"
      >
        <div *ngIf="chatId && !auth.typing[chatId]">
          {{ lastMessageText }}
        </div>
        <div *ngIf="chatId && auth.typing[chatId]">Typing..</div>
      </div>
    </div>
    <!-- 
      "CHAT_THREAD_STATUS": {
    "FORGOT_PIN": "Pin vergeten",
    "FORGOT_PIN_INFO": "De gebruiker heeft aangegeven dat hij/zij zijn/haar pincode is vergeten. De gebruiker kan niet meer bij de chat en komt mogelijk onder een nieuwe naam binnen.",
    "LOGGED_OUT": "Uitgelogd",
    "LOGGED_OUT_INFO": "De gebruiker is zelf uitgelogd en kan niet meer bij de chat.",
    "WEBCHAT": "Webchat",
    "UNWANTED_BEHAVIOUR": "Ongewenst gedrag",
    "UNWANTED_BEHAVIOUR_INFO": "De gebruiker heeft aangegeven dat er sprake is van ongewenst gedrag."
  }
    -->
    <div class="statuses">
      <!-- forgotPin as icon at the end-->
      <mat-icon
        *ngIf="chatData && chatData.forgotPin"
        [matTooltip]="'CHAT_THREAD_STATUS.FORGOT_PIN' | translate"
        (click)="
          showInfo(
            $event,
            translate.instant('CHAT_THREAD_STATUS.FORGOT_PIN_INFO')
          )
        "
        class="appointment"
        matListItemIcon
        >lock</mat-icon
      >

      <!-- loggedOut as icon at the end -->
      <mat-icon
        *ngIf="chatData && !chatData.forgotPin && chatData.loggedOutAt"
        [matTooltip]="'CHAT_THREAD_STATUS.LOGGED_OUT' | translate"
        (click)="
          showInfo(
            $event,
            translate.instant('CHAT_THREAD_STATUS.LOGGED_OUT_INFO')
          )
        "
        class="appointment"
        matListItemIcon
        >exit_to_app</mat-icon
      >

      <!-- isWebchat as icon at the end -->
      <mat-icon
        *ngIf="chatData && chatData.isWebchat"
        [matTooltip]="'CHAT_THREAD_STATUS.WEBCHAT' | translate"
        class="appointment"
        matListItemIcon
        >public</mat-icon
      >
      <!-- isUnwantedBehavior as icon at the end -->
      <mat-icon
        *ngIf="chatData && chatData.isUnwantedBehavior"
        [matTooltip]="'CHAT_THREAD_STATUS.UNWANTED_BEHAVIOUR' | translate"
        (click)="
          showInfo(
            $event,
            translate.instant('CHAT_THREAD_STATUS.UNWANTED_BEHAVIOUR_INFO')
          )
        "
        class="appointment unwanted"
        matListItemIcon
        >warning</mat-icon
      >
      <!-- unreadMessages as badge -->
      <div
        *ngIf="chatData && chatData.unreadMessages && showUnread"
        class="unread"
        matListItemMeta
      >
        <div>{{ chatData.unreadMessages }}</div>
      </div>
    </div>
  </div>
</mat-list-item>
