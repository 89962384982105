import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AppConfigService } from "../../app-config.service";
import { AuthenticationService } from "../../core/services/auth.service";
import { EncryptionService } from "../../encryption.service";
import { MatDialog } from "@angular/material/dialog";
import { InfoMessageDialogComponent } from "../../shared/info-message-dialog/info-message-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-list-user",
  templateUrl: "./list-user.component.html",
  styleUrls: ["./list-user.component.scss"],
})
export class ListUserComponent implements OnInit, OnChanges {
  @Input() status: any = "available";
  @Input() data: any = {};
  @Input() chatId: any = false;
  @Input() name;
  @Input() link = ["/chat"];
  @Input() chatData: any = false;
  showUnread = true;
  lastMessageText = "";

  constructor(
    public config: AppConfigService,
    public auth: AuthenticationService,
    public encryptionService: EncryptionService,
    public dialog: MatDialog,
    public translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chatData && changes.chatData.currentValue) {
      this.updateEncryptedMessage();
    }
  }

  async updateEncryptedMessage() {
    if (!this.encryptionService.keysSet) {
      setTimeout(async () => {
        this.updateEncryptedMessage();
      }, 200);
    } else if (
      this.chatData &&
      this.chatData.lastMessageText &&
      this.chatData.lastMessageText.startsWith("-----BEGIN PGP MESSAGE-----")
    ) {
      await this.decrypt();
    }
  }

  ngOnInit() {
    if (this.data && typeof this.data.chatData === "string") {
      this.chatData = JSON.parse(this.data.chatData);
    } else {
      this.chatData = this.data.chatData;
    }
    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    this.updateEncryptedMessage();

    if (this.chatData && this.chatData.lastMessageDate) {
      this.showUnread = new Date(this.chatData.lastMessageDate) > oneWeekAgo;
    }
  }

  async decrypt() {
    // setTimeout(async () => {
    this.lastMessageText = await this.encryptionService.decryptMessage(
      this.chatData.lastMessageText
    );
    // }, 200);
  }
  showInfo(e, html) {
    e.stopPropagation();
    e.preventDefault();
    const dialogRef = this.dialog.open(InfoMessageDialogComponent, {
      width: "600px",
      data: {
        html: html,
      },
    });
  }
}
